import { InjectionToken } from '@angular/core';

export const appStorageServiceToken: InjectionToken<IAppStorageProperties> = new InjectionToken<IAppStorageProperties>(
    'appStorageServiceToken');

export interface IAppStorageProperties {
    language: string;

    allowSkipWatchlistProfile: boolean,

    allowSkipWatchlistCreation: boolean,
}

export interface IAppStorageService {
    properties: IAppStorageProperties;
    
    getLanguage(): string;

    setLanguage(language: string): void;

    getAllowSkipWatchlistProfile(): boolean;

    setAllowSkipWatchlistProfile(allowSkipWatchlistProfile: boolean): void;

    getAllowSkipWatchlistCreation(): boolean;

    setAllowSkipWatchlistCreation(allowSkipWatchlistCreation: boolean): void;
}
